import { Button, Center, Input, VStack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import BarcodeReader from 'react-barcode-reader';

import { LOGIN_ENDPOINT } from './configs/endpoints';

import { useAppContext } from './hooks/useAppContext';

export default function Login({ children }) {
  const { user, setUser, postData, popToast } = useAppContext();

  const [password, setPassword] = useState(null);
  const [username, setUserName] = useState(null);
  const [loading, setLoading] = useState(false);

  const { token } = user;

  const handleLogin = async () => {
    setLoading(true);

    try {
      const { token, user } = await postData(LOGIN_ENDPOINT, {
        id: username,
        hash: password,
      });

      setLoading(false);

      setUser({ ...user, token });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleScan = data => {
    const { hash, id } = data;

    if (!hash || !id) popToast('Incorrect QR Code');

    setUserName(id);
    setPassword(hash);
  };

  if (token) return children;

  return (
    <Center height="100vh" width="100vw">
      <BarcodeReader onScan={handleScan} />
      <VStack spacing={5}>
        <Text>Log in 登入</Text>
        <Input value={username} onChange={e => setUserName(e.target.value)} />
        <Input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button onClick={handleLogin} isLoading={loading}>
          Log In
        </Button>
      </VStack>
    </Center>
  );
}
