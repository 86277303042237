import {
  Button,
  Center,
  Heading,
  Spinner,
  VStack,
  Image,
  Box,
  Link as AnchorLink,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Flex,
  NumberInput,
  NumberInputField,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../components/DataTable';
import { useAppContext } from '../hooks/useAppContext';
import {
  STORE_LIST_ENDPOINT,
  STORE_SHIPMENTS_ENDPOINT,
  VIEW_STORE_ORDER_ENDPOINT,
} from '../configs/endpoints';
import useSWR, { mutate } from 'swr';

function ShipmentProcessor({ shipment, setSelectedShipment }) {
  const { findProductBySKU, postData, popToast } = useAppContext();

  const [received, setReceived] = useState({});
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');

  const setReceivedById = (value, id) => {
    setReceived(current => ({ ...current, [id]: +value }));
  };

  const checkAllAsReceived = () => {
    shipment.forEach(({ id, qty }) => setReceivedById(qty, id));
  };

  const uncheckAll = () => {
    shipment.forEach(({ id }) => setReceivedById(0, id));
  };

  const submitShipmentReceipt = async () => {
    setLoading(true);

    try {
      await postData(STORE_SHIPMENTS_ENDPOINT, {
        received,
        notes,
        shipmentId: shipment[0]?.shipmentRef,
      });
      popToast(
        'success',
        `成功收貨！ Shipment ${shipment[0]?.shipmentRef} Received!`
      );
      setSelectedShipment(null);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <Flex
      h="100%"
      w="100%"
      overflow="scroll"
      direction="column"
      p={5}
      alignItems="center"
    >
      <Button
        onClick={() => setSelectedShipment(null)}
        alignSelf="flex-start"
        padding={5}
      >
        Back
      </Button>
      <Heading>{`Shipment: ${shipment[0]?.shipmentRef}`}</Heading>
      <Table variant="simple" width="90%" borderWidth="1px" mt={10} mb={10}>
        <Thead>
          <Tr>
            <Th>Image</Th>
            <Th>SKU</Th>
            <Th>Description</Th>
            <Th>Quantity Shipped</Th>
            <Th>Quantity Received</Th>
            <Th>
              Received{' '}
              <Checkbox
                onChange={e =>
                  e.target.checked ? checkAllAsReceived() : uncheckAll()
                }
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {shipment.map(({ id, sku, qty, name }) => (
            <Tr>
              <Td>
                <Image
                  src={findProductBySKU(sku)?.image}
                  boxSize="100px"
                  objectFit="cover"
                />
              </Td>
              <Td>{sku}</Td>
              <Td>{name}</Td>
              <Td>{qty}</Td>
              <Td>
                <NumberInput
                  maxW="70px"
                  value={received[id] ?? 0}
                  onChange={value => setReceivedById(value, id)}
                >
                  <NumberInputField />
                </NumberInput>
              </Td>
              <Td>
                <Checkbox
                  onChange={e =>
                    setReceivedById(e.target.checked ? qty : 0, id)
                  }
                  isChecked={received[id] > 0}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex width="90%" direction="column">
        <Flex alignSelf="flex-end" alignItems="flex-end">
          <Textarea
            placeholder="請填入任何備註"
            mr={2}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
          <Button
            colorScheme="purple"
            onClick={submitShipmentReceipt}
            isLoading={loading}
            p={5}
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default function ReceiveShipments() {
  const { user, getData } = useAppContext();
  const [store, setStore] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const { data: shipments } = useSWR(
    store ? `${STORE_SHIPMENTS_ENDPOINT}/${store}` : null,
    getData,
    {
      initialData: null,
      revalidateOnMount: true,
    }
  );

  useEffect(() => {
    if (user.access !== 'admin') setStore(user?.name);
  }, [user]);

  useEffect(() => {
    if (store) {
      mutate(`${VIEW_STORE_ORDER_ENDPOINT}/${store}`);
    }
  }, [store, selectedShipment]);

  const data = useMemo(
    () =>
      shipments
        ? Object.keys(shipments)
            .map(key => ({
              key,
              date: shipments[key][0]?.timestamp,
            }))
            .filter(({ key }) => key.length === 8)
        : [],
    [shipments]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Shipment 送貨 ID',
        accessor: 'key',
        Cell: ({ value }) => {
          return (
            <AnchorLink
              color="teal.500"
              onClick={() => setSelectedShipment(value)}
            >
              {value}
            </AnchorLink>
          );
        },
      },
      {
        Header: 'Date 日期',
        accessor: 'date',
      },
    ],
    []
  );

  const initialState = {
    sortBy: [
      {
        id: 'date',
        desc: true,
      },
    ],
  };

  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    getData(STORE_LIST_ENDPOINT).then(data => setStoreList(data));
  }, [getData]);

  if (selectedShipment) {
    return (
      <ShipmentProcessor
        shipment={shipments[selectedShipment]}
        setSelectedShipment={setSelectedShipment}
      />
    );
  }

  if (user.access === 'admin' && !store)
    return (
      <Center flexDirection="column">
        <Heading my={10}>檢視店鋪送貨單</Heading>
        <VStack spacing={4} align="stretch">
          {storeList.map(({ label, value }) => (
            <Button
              onClick={() => {
                setStore(value);
              }}
            >
              {label}
            </Button>
          ))}
        </VStack>
      </Center>
    );

  if (!shipments)
    return (
      <Center w="100%" h="100%">
        <Spinner />
      </Center>
    );

  return (
    <Box h="100%" w="100%" overflow="scroll" direction="column" p={5}>
      <DataTable
        columns={columns}
        data={data}
        initialState={initialState}
        title={`${store} Shipments`}
      />
    </Box>
  );
}
