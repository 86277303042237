import { Box, Center, Link as AnchorLink, Spinner } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { mutate } from 'swr';
import { ALL_ORDERS_ENDPOINT } from '../configs/endpoints';
import { useAppContext } from '../hooks/useAppContext';

import { Link } from 'react-router-dom';
import DataTable from '../components/DataTable';

export default function ViewOrders(props) {
  useEffect(() => {
    mutate(ALL_ORDERS_ENDPOINT);
  }, []);

  const { salesOrders } = useAppContext();

  const data = useMemo(() => salesOrders, [salesOrders]);

  const columns = useMemo(
    () => [
      {
        Header: 'Order #',
        accessor: row => row.id,
        Cell: ({ row: { original }, value }) => {
          return (
            <AnchorLink color="teal.500">
              <Link to={`/order/${original.recordId}`}>{value}</Link>
            </AnchorLink>
          );
        },
        id: 'id',
      },
      {
        Header: 'Order Date',
        accessor: 'date',
      },
      {
        Header: 'Due Date',
        accessor: 'due',
      },
      {
        Header: 'Customer',
        accessor: 'to',
      },
    ],
    []
  );

  const initialState = {
    sortBy: [
      {
        id: 'id',
        desc: true,
      },
    ],
  };

  if (salesOrders === null)
    return (
      <Center height="100%" width="100%">
        <Spinner />
      </Center>
    );

  if (!salesOrders.length)
    return (
      <Center height="100%" width="100%" fontSize="1.6em">
        No Orders Yet
      </Center>
    );

  return (
    <Box h="100%" w="100%" overflow="scroll">
      <DataTable
        data={data}
        columns={columns}
        initialState={initialState}
        title="View Orders"
      />
    </Box>
  );
}
