import {
  Button,
  Center,
  Heading,
  Spinner,
  VStack,
  Image,
  Box,
  Text,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from '../components/DataTable';
import { useAppContext } from '../hooks/useAppContext';
import {
  STORE_LIST_ENDPOINT,
  VIEW_STORE_ORDER_ENDPOINT,
} from '../configs/endpoints';
import _ from 'lodash';
import { SmallCloseIcon } from '@chakra-ui/icons';
import useSWR, { mutate } from 'swr';

function DeletionPopover({ value, deleteThis }) {
  const [deleting, setDeleting] = useState(false);

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton colorScheme="red" icon={<SmallCloseIcon />} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Confirmation!</PopoverHeader>
            <PopoverBody>
              <Center flexDirection="column">
                <Text mb={5}>Are you sure? 確定刪除嗎？ (Not reversible)</Text>
                <PopoverFooter>
                  <Button
                    onClick={() => {
                      setDeleting(true);
                      deleteThis(value).then(() => {
                        setDeleting(false);
                        onClose();
                      });
                    }}
                    colorScheme="red"
                    isLoading={deleting}
                  >
                    確認 Delete
                  </Button>
                </PopoverFooter>
              </Center>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export default function ViewStoreOrders(props) {
  const { user, getData, findProductBySKU, deleteItem } = useAppContext();
  const [store, setStore] = useState(null);
  const [consolidatedView, setConsolidatedView] = useState(true);

  const { data: orders } = useSWR(
    store ? `${VIEW_STORE_ORDER_ENDPOINT}/${store}` : null,
    getData,
    {
      initialData: null,
    }
  );

  const getOtherViewName = () => {
    return !consolidatedView ? 'View By Item' : 'View By Order';
  };

  const toggleView = () => setConsolidatedView(!consolidatedView);

  useEffect(() => {
    if (user.access !== 'admin') setStore(user?.name);
  }, [user]);

  useEffect(() => {
    if (store) {
      mutate(`${VIEW_STORE_ORDER_ENDPOINT}/${store}`);
    }
  }, [store]);

  const byOrderData = useMemo(() => orders, [orders]);

  const consolidatedData = useMemo(
    () =>
      _(orders)
        .groupBy('sku')
        .map((objs, sku) => ({ sku, qty: _.sumBy(objs, 'qty') }))
        .value()
        .sort((a, b) => a.sku.localeCompare(b.sku)),
    [orders]
  );

  const byOrderColumns = useMemo(() => {
    const deleteThis = async id => {
      await deleteItem(id);

      mutate(`${VIEW_STORE_ORDER_ENDPOINT}/${store}`, data => {
        const idx = data.findIndex(item => item.id === id);
        const ret = [...data];
        ret.splice(idx, 1);
        return ret;
      });
    };
    return [
      {
        Header: 'Order #',
        accessor: 'order',
      },

      {
        Header: 'SKU',
        accessor: 'sku',
      },
      {
        Header: 'Description',
        accessor: row => findProductBySKU(row.sku)?.description,
      },
      {
        Header: 'Quantity',
        accessor: 'qty',
      },
      {
        Header: 'Delete',
        accessor: 'id',
        Cell: ({ value }) => (
          <DeletionPopover value={value} deleteThis={deleteThis} />
        ),
      },
    ];
  }, [findProductBySKU, deleteItem, store]);

  const consolidatedColumns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: row => findProductBySKU(row.sku)?.image,
        Cell: ({ value }) => {
          return <Image src={value} boxSize="100px" objectFit="cover" />;
        },
      },
      {
        Header: 'SKU',
        accessor: 'sku',
      },
      {
        Header: 'Description',
        accessor: row => findProductBySKU(row.sku)?.description,
      },
      {
        Header: 'Quantity',
        accessor: 'qty',
      },
    ],
    [findProductBySKU]
  );

  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    getData(STORE_LIST_ENDPOINT).then(data => setStoreList(data));
  }, [getData]);

  if (user.access === 'admin' && !store)
    return (
      <Center flexDirection="column">
        <Heading my={10}>檢視店鋪訂單</Heading>
        <VStack spacing={4} align="stretch">
          {storeList.map(({ label, value }) => (
            <Button
              onClick={() => {
                setStore(value);
              }}
            >
              {label}
            </Button>
          ))}
        </VStack>
      </Center>
    );

  if (!orders)
    return (
      <Center w="100%" h="100%">
        <Spinner />
      </Center>
    );

  return (
    <Box h="100%" w="100%" overflow="scroll" direction="column" p={5}>
      <Button variant="solid" onClick={toggleView}>
        {getOtherViewName()}
      </Button>

      <DataTable
        columns={consolidatedView ? consolidatedColumns : byOrderColumns}
        data={consolidatedView ? consolidatedData : byOrderData}
        title={`${store} Unfulfilled Orders`}
      />
    </Box>
  );
}
