import { useToast } from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
import useLocalStorage from './useLocalStorage';
import { syncWithLocalStorage } from 'swr-sync-storage';
import {
  ALL_ORDERS_ENDPOINT,
  CATALOG_ENDPOINT,
  DELETE_ITEM_ENDPOINT,
  WHOLESALE_CATALOG_ENDPOINT,
  DELETE_ORDER_ENDPOINT,
} from '../configs/endpoints';

const AppContext = React.createContext({});

export const AppContextProvider = props => {
  const { children } = props;

  syncWithLocalStorage();

  const [user, setUser] = useLocalStorage('user', {});
  const [savedWholeOrder, setSavedWholesaleOrder] = useLocalStorage(
    'order',
    {}
  );

  const { token } = user;

  const toast = useToast();

  const logOut = () => {
    setUser({});
  };

  const popToast = (status, message) => {
    toast({
      title: status,
      description: message,
      status: status,
      duration: 3000,
      isClosable: true,
      position: 'top',
    });
  };

  const postData = async (endpoint, body) => {
    try {
      const res = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: token ? token : undefined,
        },
        body: JSON.stringify(body),
      });
      if (res.ok) {
        return res.json();
      }

      throw new Error(res.status + ' ' + res.statusText);
    } catch (err) {
      popToast('error', err.message);
      throw new Error(err.message);
    }
  };

  const deleteItem = async id => {
    try {
      const res = await fetch(`${DELETE_ITEM_ENDPOINT}/${id}`, {
        method: 'DELETE',
        headers: {
          authorization: token ? token : undefined,
        },
      });
      if (res.ok) {
        return res.json();
      }

      throw new Error(res.statusText);
    } catch (err) {
      popToast('error', err.message);
      throw new Error(err.message);
    }
  };
  const deleteOrder = async id => {
    try {
      const res = await fetch(`${DELETE_ORDER_ENDPOINT}/${id}`, {
        method: 'DELETE',
        headers: {
          authorization: token ? token : undefined,
        },
      });
      if (res.ok) {
        return res.json();
      }

      throw new Error(res.statusText);
    } catch (err) {
      popToast('error', err.message);
      throw new Error(err.message);
    }
  };

  const getData = async endpoint => {
    try {
      const res = await fetch(endpoint, {
        method: 'GET',
        headers: {
          authorization: token ? token : undefined,
        },
      });
      if (res.ok) {
        return res.json();
      }

      throw new Error(res.statusText);
    } catch (err) {
      popToast('error', err.message);
      throw new Error(err.message);
    }
  };

  const { data: catalog } = useSWR(token ? CATALOG_ENDPOINT : null, getData, {
    initialData: [],
  });

  const { data: wholesaleCatalog } = useSWR(
    token ? WHOLESALE_CATALOG_ENDPOINT : null,
    getData,
    {
      initialData: [],
    }
  );

  const { data: salesOrders } = useSWR(
    token ? ALL_ORDERS_ENDPOINT : null,
    getData,
    { initialData: null }
  );

  const findProductBySKU = sku => {
    return wholesaleCatalog.find(product => product.sku === sku);
  };

  const findProductByBarcode = barcodeScanned =>
    catalog.find(({ barcode }) => barcode === barcodeScanned);

  const context = {
    logOut,
    user,
    setUser,
    savedWholeOrder,
    setSavedWholesaleOrder,
    popToast,
    postData,
    getData,
    catalog,
    wholesaleCatalog,
    salesOrders,
    findProductBySKU,
    findProductByBarcode,
    deleteItem,
    deleteOrder,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
};
