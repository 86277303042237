import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useAppContext } from '../hooks/useAppContext';
import { GET_ORDER_ENDPOINT, ALL_ORDERS_ENDPOINT } from '../configs/endpoints';
import { mutate } from 'swr';

const DeletionPopover = ({ deleteThis }) => {
  const [deleting, setDeleting] = useState(false);

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button colorScheme="red">Delete</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Confirmation!</PopoverHeader>
            <PopoverBody>
              <Center flexDirection="column">
                <Text mb={5}>Are you sure? 確定刪除嗎？ (Not reversible)</Text>
                <PopoverFooter>
                  <Button
                    isLoading={deleting}
                    colorScheme="red"
                    onClick={() => {
                      setDeleting(true);
                      deleteThis().then(() => {
                        setDeleting(false);
                        onClose();
                      });
                    }}
                  >
                    確認 Delete
                  </Button>
                </PopoverFooter>
              </Center>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default function ViewOrder() {
  const history = useHistory();
  const { orderId } = useParams();

  const [orderDetails, setOrderDetails] = useState(null);
  const {
    getData,
    findProductBySKU,
    user,
    popToast,
    deleteOrder,
  } = useAppContext();

  useEffect(() => {
    getData(`${GET_ORDER_ENDPOINT}/${orderId}`).then(setOrderDetails);
  }, [orderId, getData]);

  const deleteThis = async () => {
    try {
      await deleteOrder(orderId);

      mutate(ALL_ORDERS_ENDPOINT, data => {
        const idx = data.findIndex(order => order.recordId === orderId);
        const ret = [...data];
        ret.splice(idx, 1);
        return ret;
      });

      popToast('success', 'Order Deleted');

      history.push('/view-orders');
    } catch (err) {
      console.error(err);
      popToast('error', 'Error, could not delete order');
    }
  };

  return (
    <Flex height="100%" width="100%" overflow="scroll" p={5} direction="column">
      <HStack mb={5} spacing="2rem">
        <Link to="/view-orders">
          <Button>Back</Button>
        </Link>
        <Button
          onClick={() =>
            window.open(
              `https://masklab-qrcode.herokuapp.com/invoice-pdf/${orderId}`
            )
          }
        >
          Download Invoice
        </Button>
        {user.access === 'admin' && <DeletionPopover deleteThis={deleteThis} />}
      </HStack>
      {orderDetails ? (
        <Flex flexDirection="column" alignItems="center">
          <Box mb={10}>
            <Heading size="md">Order {orderDetails.ID}</Heading>
          </Box>
          <Grid templateColumns="10% 10% 10% 70%" gap="0.5rem" maxW="90%">
            <Center>
              <strong>Qty</strong>
            </Center>
            <Center>
              <strong>Image</strong>
            </Center>
            <Center>
              <strong>SKU</strong>
            </Center>
            <Center>
              <strong>Description</strong>
            </Center>
            {orderDetails.items
              .sort((a, b) => a.sku.localeCompare(b.sku))
              .map(({ sku, name, quantity }) => {
                const { image } = findProductBySKU(sku) || { image: null };
                return (
                  <>
                    <Center>{quantity}</Center>
                    <Center>
                      <Image src={image} boxSize="100px" />
                    </Center>

                    <Center>{sku}</Center>
                    <Center textAlign="center">{name}</Center>
                  </>
                );
              })}
          </Grid>
        </Flex>
      ) : (
        <Center w="100%" h="100%">
          <Spinner />
        </Center>
      )}
    </Flex>
  );
}
