import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Center,
  Grid,
  Spinner,
  Image,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Wrap,
  WrapItem,
  NumberInput,
  IconButton,
  Heading,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  EditablePreview,
  EditableInput,
  Editable,
  HStack,
} from '@chakra-ui/react';

import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  SearchIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import { groupBy } from 'lodash';
import { mutate } from 'swr';
import { useAppContext } from '../hooks/useAppContext';
import {
  WHOLESALE_CATALOG_ENDPOINT,
  WHOLESALE_ORDER_ENDPOINT,
} from '../configs/endpoints';
import DatePicker from '../components/DatePicker';
import moment from 'moment';

const generateEmptyOrder = () => {
  const id = Math.floor(Math.random() * 1000000);
  return { id, products: {} };
};

function WholesaleOrder() {
  const [filterBy, setFilterBy] = useState(null);
  const [packagingType, setPackagingType] = useState(null);
  const [productType, setProductType] = useState(null);
  const [orderItems, setOrderItems] = useState(generateEmptyOrder());
  const [dueDate, setDueDate] = useState(new Date());
  const [submitting, setSubmitting] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [client, setClient] = useState(null);

  const {
    wholesaleCatalog: products,
    popToast,
    postData,
    findProductBySKU,
    savedWholeOrder,
    setSavedWholesaleOrder,
  } = useAppContext();

  useEffect(() => {
    mutate(WHOLESALE_CATALOG_ENDPOINT);
  }, []);

  const handleFilterChange = event => {
    setFilterBy(event.target.value);
  };

  const total = Object.values(orderItems.products).reduce(
    (acc, { quantity, price }) => {
      return acc + quantity * price;
    },
    0
  );

  const productTypes = Object.keys(groupBy(products, 'type'));
  const packagingTypes = Object.keys(groupBy(products, 'packagingType'));

  const filterFunc = product => {
    const filters = [];

    if (packagingType) {
      filters.push({ key: 'packagingType', value: packagingType });
    }

    if (productType) {
      filters.push({ key: 'type', value: productType });
    }

    if (!filters.length) return true;

    return filters.every(filter => product[filter.key] === filter.value);
  };

  const productsToDisplay = products
    .filter(filterFunc)
    .filter(({ description, sku }) => {
      if (!filterBy) return true;
      const rg = new RegExp(filterBy, 'i');
      return description.match(rg) || sku.match(rg);
    });

  const handleQtyChange = (sku, n) => {
    if (n <= 0) {
      handleDelete(sku);
      return;
    }

    setOrderItems(prev => {
      const updatedOrderItems = { ...prev.products };
      updatedOrderItems[sku] = { ...updatedOrderItems[sku], quantity: n };

      return {
        ...prev,
        products: updatedOrderItems,
      };
    });
  };

  const handlePriceChange = (sku, n) => {
    setOrderItems(prev => {
      const updatedOrderItems = { ...prev.products };

      updatedOrderItems[sku] = {
        ...updatedOrderItems[sku],
        price: +n.replace(/^\$/, ''),
      };

      return {
        ...prev,
        products: updatedOrderItems,
      };
    });
  };

  const handleDelete = sku => {
    setOrderItems(prev => {
      const updatedOrderItems = { ...prev.products };

      delete updatedOrderItems[sku];

      return {
        ...prev,
        products: updatedOrderItems,
      };
    });
  };

  const toggleOrder = ({ sku, masterCartonQty, price }) => {
    setOrderItems(prev => {
      const updatedOrderItems = { ...prev.products };

      if (updatedOrderItems[sku]) {
        delete updatedOrderItems[sku];
      } else {
        updatedOrderItems[sku] = {
          quantity: masterCartonQty || 1,
          price: price || 0,
        };
      }

      return {
        ...prev,
        products: updatedOrderItems,
      };
    });
  };

  const handleSubmit = () => {
    const keysArray = Object.keys(orderItems.products);
    if (!keysArray.length)
      return popToast('error', '訂單不可為空 Order cannot be empty!');

    setSubmitting(true);

    const items = keysArray.map(key => {
      console.log('Key ', key);
      const { quantity, price } = orderItems.products[key];
      return { quantity, price, sku: key };
    });

    postData(WHOLESALE_ORDER_ENDPOINT, { items, dueDate, customerInfo, client })
      .then(orderId => {
        popToast('success', '成功加入訂單');
        setOrderItems(generateEmptyOrder());
        setSubmitting(false);
        window.open(
          `https://masklab-qrcode.herokuapp.com/invoice-pdf/${orderId}`
        );
      })
      .catch(err => {
        popToast('error', err.response ? err.response.data : err.message);
        setSubmitting(false);
      });
  };

  const handleSaveForLater = () => {
    const lastUpdated = Date.now();

    setSavedWholesaleOrder(prev => ({
      ...prev,
      [orderItems.id]: { ...orderItems, lastUpdated },
    }));

    setOrderItems(generateEmptyOrder());
  };

  if (!products.length)
    return (
      <Center width="100vw" height="100vh">
        <Spinner size="lg" />
      </Center>
    );

  return (
    <Flex height="100%" width="100%">
      <Flex flexGrow={1} direction="column">
        <Wrap p={5} spacing={5}>
          <WrapItem>
            <Flex
              justifySelf="start"
              position="relative"
              bg="rgba(255,255,255,0.06)"
              border="1px solid"
              borderRadius="0.25rem"
              borderColor="inherit"
            >
              <Input
                width="calc(100% - 25px)"
                placeholder="搜尋 Search"
                border="none"
                bg="unset"
                _focus={{ borderColor: 'none', boxShadow: 'none' }}
                value={filterBy}
                onChange={handleFilterChange}
              />
              {filterBy && (
                <Flex
                  position="absolute"
                  right="1.5rem"
                  alignSelf="center"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {
                    setFilterBy('');
                  }}
                  cursor="pointer"
                  height="20px"
                  width="20px"
                >
                  <SmallCloseIcon size="10px" opacity={0.3} />
                </Flex>
              )}
              <SearchIcon
                position="absolute"
                alignSelf="center"
                right="0.5rem"
              />
            </Flex>
          </WrapItem>
          <WrapItem>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {packagingType || `包裝類型 Packaging Type`}
              </MenuButton>
              <MenuList>
                {packagingTypes.map(type => (
                  <MenuItem
                    icon={type === packagingType && <CheckIcon />}
                    onClick={() => setPackagingType(type)}
                  >
                    {type}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </WrapItem>
          <WrapItem>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {productType || `產品類型 Product Type`}
              </MenuButton>
              <MenuList>
                {productTypes.map(type => (
                  <MenuItem
                    icon={type === productType && <CheckIcon />}
                    onClick={() => setProductType(type)}
                  >
                    {type}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </WrapItem>
        </Wrap>
        <Flex p={5} flexGrow={1} overflow="scroll" direction="column">
          <Grid
            templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            gap={10}
            width="100%"
            height="100%"
          >
            {productsToDisplay.length ? (
              productsToDisplay.map(product => (
                <Center
                  borderRadius="20px"
                  border={
                    !!orderItems.products[product.sku]
                      ? '2px solid #805AD5'
                      : undefined
                  }
                  onClick={() => toggleOrder(product)}
                  cursor="pointer"
                  flexDirection="column"
                  p={2}
                >
                  <Image
                    boxSize="180px"
                    src={product.image}
                    fit="cover"
                  ></Image>
                  <Text textAlign="center">{product.sku}</Text>
                  <Text textAlign="center">{product.description}</Text>
                </Center>
              ))
            ) : (
              <Center>無產品 No Products</Center>
            )}
          </Grid>
        </Flex>
      </Flex>
      <Flex minW="700px" borderLeftWidth="0.5px" direction="column">
        <Box>
          <Heading size="md" p={5} textAlign="center">
            WHOLESALE ORDER 批發訂單
          </Heading>
          <Flex px={5} py={3}>
            <Text fontSize="md" mr={5}>
              Customer
            </Text>
            <Input
              value={client}
              onChange={e => setClient(e.target.value)}
              placeholder="Name"
              size="sm"
              flexGrow={1}
            />
          </Flex>
          <Flex px={5} py={3}>
            <Text fontSize="md">Customer Details</Text>
            <Textarea
              value={customerInfo}
              onChange={e => setCustomerInfo(e.target.value)}
              placeholder="Full address and contact"
              size="sm"
              flexGrow={1}
            />
          </Flex>
          <Grid
            templateColumns="15% 10% 20% 15% 15% 15% 10%"
            templateRows="auto"
            maxW="700px"
          >
            <Center>圖</Center>
            <Center>編號</Center>
            <Center>名稱</Center>
            <Center>個數</Center>
            <Center>價錢</Center>
            <Center>總金額</Center>
            <Center></Center>
          </Grid>
        </Box>
        <Box overflow="scroll" flexGrow={1}>
          <Grid
            templateColumns="15% 10% 20% 15% 15% 15% 10%"
            templateRows="auto"
            maxW="700px"
          >
            {Object.keys(orderItems.products).map(sku => {
              const product = findProductBySKU(sku);
              const { image, shortname } = product;
              const { quantity, price } = orderItems.products[sku];

              return (
                <>
                  <Box key={sku}>
                    <Image src={image} size="100px" />
                  </Box>
                  <Center>{sku}</Center>
                  <Center>{shortname}</Center>
                  <Center>
                    <NumberInput
                      value={quantity}
                      onChange={n => handleQtyChange(sku, n)}
                      step={1}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Center>
                  <Center>
                    <Editable
                      defaultValue={`$${price.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`}
                      onSubmit={n => handlePriceChange(sku, n)}
                    >
                      <EditableInput />
                      <EditablePreview />
                    </Editable>
                  </Center>
                  <Center>
                    $
                    {(quantity * price).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                  </Center>
                  <Center>
                    <IconButton
                      aria-label="delete item"
                      icon={<SmallCloseIcon />}
                      colorScheme="red"
                      borderRadius="50%"
                      padding={0}
                      onClick={() => handleDelete(sku)}
                    />
                  </Center>
                </>
              );
            })}
          </Grid>
        </Box>

        <HStack spacing="24px">
          <Box>
            <Button
              colorScheme="green"
              marginLeft="10px"
              onClick={handleSaveForLater}
              isLoading={submitting}
            >
              Save For Later
            </Button>
          </Box>
          {Object.values(savedWholeOrder).length && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronUpIcon />}>
                Saved Orders
              </MenuButton>
              <MenuList>
                {Object.values(savedWholeOrder)
                  .sort((a, b) => a.lastUpdated - b.lastUpdated)
                  .map(order => (
                    <MenuItem onClick={() => setOrderItems(order)}>
                      {moment(order.lastUpdated).format('MM-D-YYYY, hh:mma')}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          )}

          <Box flexGrow={1} />
        </HStack>
        <Flex p={3}>
          <Flex alignItems="center">
            <Box mr={2}>Due Date:</Box>
            <Box w="120px">
              <DatePicker onChange={setDueDate} selected={dueDate} />
            </Box>
          </Flex>

          <Flex alignItems="center" ml={5}>
            <Box mr={2}>Total:</Box>
            <Box w="120px">
              ${total.toLocaleString('en-US', { minimumFractionDigits: 2 })}
            </Box>
          </Flex>

          <Box flexGrow={1} />
          <Box>
            <Button
              colorScheme="purple"
              onClick={handleSubmit}
              isLoading={submitting}
            >
              提交訂單 Submit Order
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default WholesaleOrder;
