const API_ROOT =
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_STAGE === 'staging'
    ? 'https://api.masklab.hk'
    : 'http://localhost:8080';

export const CATALOG_ENDPOINT = `${API_ROOT}/orders/catalog`;

export const WHOLESALE_CATALOG_ENDPOINT = `${API_ROOT}/logistics/wholesale-catalog`;

export const LOGIN_ENDPOINT = `${API_ROOT}/logistics/login`;

export const STORE_ORDER_ENDPOINT = `${API_ROOT}/orders/create-store-order`;

export const ALL_ORDERS_ENDPOINT = `${API_ROOT}/orders/`;

export const GET_ORDER_ENDPOINT = `${API_ROOT}/orders/order`;

export const WHOLESALE_ORDER_ENDPOINT = `${API_ROOT}/orders/create-wholesale-order`;

export const VIEW_STORE_ORDER_ENDPOINT = `${API_ROOT}/orders/store`;

export const DELETE_ITEM_ENDPOINT = `${API_ROOT}/orders/item`;

export const DELETE_ORDER_ENDPOINT = `${API_ROOT}/orders/order`;

export const VIEW_ORDERS_ENDPOINT = `${API_ROOT}/view-orders`;

export const STORE_SHIPMENTS_ENDPOINT = `${API_ROOT}/logistics/shipments`;

export const FRANCHISEE_SHIPMENTS_ENDPOINT = `${API_ROOT}/logistics/franchisee/shipments`;

export const STORE_LIST_ENDPOINT = `${API_ROOT}/logistics/stores`;
