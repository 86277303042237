import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link as AnchorLink,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import StoreOrder from 'containers/store-order';
import { useAppContext } from 'hooks/useAppContext';
import logo from 'images/masklab-whitebg-logo.png';
import { Route, Switch, Link } from 'react-router-dom';
import ViewOrders from 'containers/view-orders';
import ViewOrder from 'containers/view-order';
import WholesaleOrder from 'containers/wholesale-order';
import FranchiseeStoreOrder from 'containers/franchisee-store-order';
import Welcome from 'containers/welcome';
import ViewStoreOrders from 'containers/view-store-orders';
import ReceiveShipments from 'containers/receive-shipments';
import ReceiveFranchiseeShipments from 'containers/receive-franchisee-shipments';

export default function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { logOut, user } = useAppContext();

  const franchisees = ['CCCP'];

  return (
    <Flex direction="column" height="100vh" width="100vw">
      <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader>
              <Flex alignItems="center">
                <Avatar name={user.name}></Avatar>
                <Box ml={2}>{user.name}</Box>
              </Flex>
            </DrawerHeader>

            <DrawerBody>
              <Center flexDirection="column" height="100%">
                <VStack spacing={10}>
                  {(user.access === 'admin' ||
                    user.access.includes('orders:store')) && (
                    <>
                      <AnchorLink colorScheme="blue">
                        <Link to="/view-store-orders">
                          檢視店鋪訂單 View Store Orders
                        </Link>
                      </AnchorLink>
                      <AnchorLink colorScheme="blue">
                        <Link to="/store-order">
                          店鋪下單 Place Store Order
                        </Link>
                      </AnchorLink>

                      <AnchorLink colorScheme="blue">
                        <Link to="/receive-shipments">
                          店鋪收貨 Receive Store Shipment
                        </Link>
                      </AnchorLink>
                    </>
                  )}

                  {(user.access === 'admin' ||
                    user.access.includes('orders:wholesale')) && (
                    <AnchorLink colorScheme="blue">
                      <Link to="/view-orders">
                        檢視所有訂單 View All Orders
                      </Link>
                    </AnchorLink>
                  )}
                  {(user.access === 'admin' ||
                    user.access.includes('orders:wholesale')) && (
                    <AnchorLink colorScheme="blue">
                      <Link to="/wholesale-order">
                        批發下單 Place Wholesale Order
                      </Link>
                    </AnchorLink>
                  )}

                  {(user.access === 'admin' ||
                    user.access.includes('orders:purchasing')) && (
                    <AnchorLink colorScheme="blue">
                      <Link to="/purchase-order">
                        物料下單 Place Purchase Order
                      </Link>
                    </AnchorLink>
                  )}

                  {(franchisees.includes(user.name) ||
                    user.access.includes('franchisees')) && (
                    <AnchorLink colorScheme="blue">
                      <Link to="/view-store-orders">
                        檢視店鋪訂單 View Store Orders
                      </Link>
                    </AnchorLink>
                  )}

                  {(franchisees.includes(user.name) ||
                    user.access.includes('franchisees')) && (
                    <AnchorLink colorScheme="blue">
                      <Link to="/receive-franchisee-shipments">
                        加盟店收貨 Receive Shipment
                      </Link>
                    </AnchorLink>
                  )}

                  {(franchisees.includes(user.name) ||
                    user.access.includes('franchisees')) && (
                    <AnchorLink
                      colorScheme="blue"
                      style={{ textAlign: 'center' }}
                    >
                      <Link to="/franchisee-store-order">
                        下加盟店訂單 Place Franchisee Store Order
                      </Link>
                    </AnchorLink>
                  )}
                </VStack>
              </Center>
            </DrawerBody>
            <DrawerFooter>
              <Button onClick={logOut}>登出 Logout</Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Center p={3} borderBottomWidth="0.5px" height="70px">
        <HamburgerIcon boxSize="20px" cursor="pointer" onClick={onOpen} />
        <Box ml={5}>
          <img width="150" src={logo} alt="logo" />
        </Box>
        <Box flexGrow={1} /> <ColorModeSwitcher />
      </Center>
      <Box width="100%" height="calc(100% - 70px)">
        <Switch>
          <Route path={`/order/:orderId`}>
            <ViewOrder />
          </Route>
          <Route path="/store-order">
            <StoreOrder />
          </Route>
          <Route path="/wholesale-order">
            <WholesaleOrder />
          </Route>

          <Route path="/view-store-orders">
            <ViewStoreOrders />
          </Route>
          <Route path="/view-store-orders">
            <ViewStoreOrders />
          </Route>
          <Route path="/receive-franchisee-shipments">
            <ReceiveFranchiseeShipments />
          </Route>
          <Route path="/view-orders">
            <ViewOrders />
          </Route>
          <Route path="/franchisee-store-order">
            <FranchiseeStoreOrder />
          </Route>
          <Route path="/receive-shipments">
            <ReceiveShipments />
          </Route>
          <Route path="/">
            <Welcome />
          </Route>
        </Switch>
      </Box>
    </Flex>
  );
}
