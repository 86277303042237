import { Center, Heading } from '@chakra-ui/react';
import React from 'react';

export default function Welcome() {
  return (
    <Center w="100%" h="100%" flexDirection="column">
      <Heading d="block" p={2}>
        Welcome to masklab's internal ordering system
      </Heading>
      <Heading d="block" p={2}>
        歡迎來到 masklab 的內部訂貨系統
      </Heading>
    </Center>
  );
}
