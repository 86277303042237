import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Center,
  Grid,
  Spinner,
  Image,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Wrap,
  WrapItem,
  NumberInput,
  IconButton,
  Heading,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

import {
  CheckIcon,
  ChevronDownIcon,
  SearchIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import { groupBy } from 'lodash';
import useSWR, { mutate } from 'swr';
import BarcodeReader from 'react-barcode-reader';
import { useAppContext } from '../hooks/useAppContext';
import {
  CATALOG_ENDPOINT,
  STORE_ORDER_ENDPOINT,
  VIEW_STORE_ORDER_ENDPOINT,
} from '../configs/endpoints';
import DatePicker from '../components/DatePicker';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function StoreOrder() {
  const [filterBy, setFilterBy] = useState(null);
  const [packagingType, setPackagingType] = useState(null);
  const [productType, setProductType] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [dueDate, setDueDate] = useState(new Date());
  const [submitting, setSubmitting] = useState(false);

  const {
    catalog: products,
    popToast,
    postData,
    findProductBySKU,
    findProductByBarcode,
    user,
    getData,
  } = useAppContext();

  const store = user.name;
  const { data: orders } = useSWR(
    store ? `${VIEW_STORE_ORDER_ENDPOINT}/${store}` : null,
    getData,
    {
      initialData: [],
    }
  );

  useEffect(() => {
    mutate(CATALOG_ENDPOINT);
  }, []);

  const orderedSKUs = useMemo(
    () => orders.map(({ sku }) => sku).filter(onlyUnique),
    [orders]
  );

  const handleFilterChange = event => {
    setFilterBy(event.target.value);
  };

  const productTypes = Object.keys(groupBy(products, 'type'));
  const packagingTypes = Object.keys(groupBy(products, 'packagingType'));

  const filterFunc = product => {
    const filters = [];

    if (packagingType) {
      filters.push({ key: 'packagingType', value: packagingType });
    }

    if (productType) {
      filters.push({ key: 'type', value: productType });
    }

    if (!filters.length) return true;

    return filters.every(filter => product[filter.key] === filter.value);
  };

  const productsToDisplay = products
    .filter(filterFunc)
    .filter(({ description, sku }) => {
      if (!filterBy) return true;
      const rg = new RegExp(filterBy, 'i');
      return description.match(rg) || sku.match(rg);
    });

  const handleQtyChange = (sku, n) => {
    setOrderItems(currOrderItems => {
      const updatedOrderItems = { ...currOrderItems };

      if (n <= 0) {
        delete updatedOrderItems[sku];
      } else {
        updatedOrderItems[sku] = n;
      }

      return updatedOrderItems;
    });
  };

  const handleDelete = sku => {
    setOrderItems(currOrderItems => {
      const updatedOrderItems = { ...currOrderItems };

      delete updatedOrderItems[sku];

      return updatedOrderItems;
    });
  };

  const handleScanItem = data => {
    const product = findProductByBarcode(data);

    if (!product) {
      return popToast('error', '無此產品');
    }

    const { sku } = product;

    setOrderItems(currOrderItems => {
      const updatedOrderItems = { ...currOrderItems };

      if (!updatedOrderItems[sku]) {
        updatedOrderItems[sku] = 1;
      }

      return updatedOrderItems;
    });
  };

  const toggleOrder = product => {
    const { sku } = product;

    setOrderItems(currOrderItems => {
      const updatedOrderItems = { ...currOrderItems };

      if (updatedOrderItems[sku]) {
        delete updatedOrderItems[sku];
      } else {
        updatedOrderItems[sku] = 1;
      }

      return updatedOrderItems;
    });
  };

  const handleSubmit = () => {
    const keysArray = Object.keys(orderItems);
    if (!keysArray.length)
      return popToast('error', '訂單不可為空 Order cannot be empty!');

    setSubmitting(true);

    const items = keysArray.map(key => {
      const product = findProductBySKU(key);
      const qty = orderItems[key] * (product.masterCartonQty || 1);
      return { qty, sku: key };
    });

    postData(STORE_ORDER_ENDPOINT, {
      items,
      dueDate,
      store,
      userType: 'Franchise',
    })
      .then(orderId => {
        popToast('success', '成功加入訂單');
        setOrderItems({});
        setSubmitting(false);
        window.open(
          `https://masklab-qrcode.herokuapp.com/order-pdf/${orderId}`
        );
      })
      .catch(err => {
        popToast('error', err.response ? err.response.data : err.message);
        setSubmitting(false);
      });
  };

  if (!products.length)
    return (
      <Center width="100vw" height="100vh">
        <Spinner size="lg" />
      </Center>
    );

  return (
    <Flex height="100%" width="100%">
      <BarcodeReader onError={console.error} onScan={handleScanItem} />
      <Flex flexGrow={1} direction="column">
        <Wrap p={5} spacing={5}>
          <WrapItem>
            <Flex
              justifySelf="start"
              position="relative"
              bg="rgba(255,255,255,0.06)"
              border="1px solid"
              borderRadius="0.25rem"
              borderColor="inherit"
            >
              <Input
                width="calc(100% - 25px)"
                placeholder="搜尋 Search"
                border="none"
                bg="unset"
                _focus={{ borderColor: 'none', boxShadow: 'none' }}
                value={filterBy}
                onChange={handleFilterChange}
              />
              {filterBy && (
                <Flex
                  position="absolute"
                  right="1.5rem"
                  alignSelf="center"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {
                    setFilterBy('');
                  }}
                  cursor="pointer"
                  height="20px"
                  width="20px"
                >
                  <SmallCloseIcon size="10px" opacity={0.3} />
                </Flex>
              )}
              <SearchIcon
                position="absolute"
                alignSelf="center"
                right="0.5rem"
              />
            </Flex>
          </WrapItem>
          <WrapItem>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {packagingType || `包裝類型 Packaging Type`}
              </MenuButton>
              <MenuList>
                {packagingTypes.map(type => (
                  <MenuItem
                    icon={type === packagingType && <CheckIcon />}
                    onClick={() => setPackagingType(type)}
                  >
                    {type}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </WrapItem>
          <WrapItem>
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {productType || `產品類型 Product Type`}
              </MenuButton>
              <MenuList>
                {productTypes.map(type => (
                  <MenuItem
                    icon={type === productType && <CheckIcon />}
                    onClick={() => setProductType(type)}
                  >
                    {type}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </WrapItem>
        </Wrap>
        <Flex p={5} flexGrow={1} overflow="scroll" direction="column">
          <Grid
            templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            gap={10}
            width="100%"
            height="100%"
          >
            {productsToDisplay.length ? (
              productsToDisplay.map(product => (
                <Center
                  borderRadius="20px"
                  border={
                    !!orderItems[product.sku] ? '2px solid #805AD5' : undefined
                  }
                  onClick={() => toggleOrder(product)}
                  cursor="pointer"
                  flexDirection="column"
                  p={2}
                >
                  <Image
                    boxSize="180px"
                    src={product.image}
                    fit="cover"
                  ></Image>
                  <Text textAlign="center">{product.sku}</Text>
                  <Text textAlign="center">{product.description}</Text>
                </Center>
              ))
            ) : (
              <Center>無產品 No Products</Center>
            )}
          </Grid>
        </Flex>
      </Flex>
      <Flex minW="500px" borderLeftWidth="0.5px" direction="column">
        <Box>
          <Heading size="md" p={5} textAlign="center">
            STORE ORDER 店鋪訂單
          </Heading>
          <Grid
            templateColumns="20% 20% 20% 15% 15% 10%"
            templateRows="auto"
            maxW="500px"
          >
            <Center>圖</Center>
            <Center>編號</Center>
            <Center>名稱</Center>
            <Center>箱數</Center>
            <Center>個數</Center>
            <Center></Center>
          </Grid>
        </Box>
        <Box overflow="scroll" flexGrow={1}>
          <Grid
            templateColumns="20% 20% 20% 15% 15% 10%"
            templateRows="auto"
            maxW="500px"
          >
            {Object.keys(orderItems).map(sku => {
              console.log({ sku, result: findProductBySKU(sku) });
              const product = findProductBySKU(sku);

              const { image, shortname, masterCartonQty } = product;
              const quantity = orderItems[sku];

              return (
                <>
                  <Box
                    key={sku}
                    borderTop={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                    borderBottom={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                  >
                    <Image src={image} size="100px" />
                  </Box>
                  <Center
                    borderTop={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                    borderBottom={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                  >
                    {sku}
                  </Center>
                  <Center
                    borderTop={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                    borderBottom={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                  >
                    {shortname}
                  </Center>
                  <Center
                    borderTop={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                    borderBottom={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                  >
                    <NumberInput
                      value={quantity}
                      onChange={n => handleQtyChange(sku, n)}
                      step={1}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Center>
                  <Center
                    borderTop={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                    borderBottom={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                  >
                    {quantity * (masterCartonQty || 1)}
                  </Center>
                  <Center
                    borderTop={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                    borderBottom={
                      orderedSKUs.includes(sku) ? '1px solid red' : null
                    }
                  >
                    <IconButton
                      aria-label="delete item"
                      icon={<SmallCloseIcon />}
                      colorScheme="red"
                      borderRadius="50%"
                      padding={0}
                      onClick={() => handleDelete(sku)}
                    />
                  </Center>
                </>
              );
            })}
          </Grid>
        </Box>
        <Flex p={3}>
          <Flex alignItems="center">
            <Box mr={2}>Due Date:</Box>
            <Box w="120px">
              <DatePicker onChange={setDueDate} selected={dueDate} />
            </Box>
          </Flex>

          <Box flexGrow={1} />
          <Box>
            <Button
              colorScheme="purple"
              onClick={handleSubmit}
              isLoading={submitting}
            >
              提交訂單 Submit Order
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default StoreOrder;
